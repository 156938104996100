import { useSnackbarStore } from "@/stores/snackbar";

export const useSnack = () => {
  const { success, error, info } = useSnackbarStore();

  return {
    success,
    error,
    info,
  };
};
